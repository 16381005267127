<template>
  <DxDataGrid
    id="profit-and-loss-data-grid"
    ref="gridView"
    class="sxr-grid regular-text-color"
    :class="{ 'filter-panel-expanded': expandFilters }"
    width="100%"
    height="100%"
    :data-source="datasource"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="true"
    :remote-operations="true"
    :word-wrap-enabled="true"
    :no-data-text="noResultsFoundMessage"
    :column-auto-width="true"
    :allow-column-reordering="false"
    :allow-column-resizing="false"
    column-resizing-mode="widget"
    :on-row-prepared="onRowPrepared"
    @exporting="onExporting"
    @exported="onExported"
  >
    <DxPaging :enabled="false" />
    <DxGrouping :allow-collapsing="false" />
    <DxToolbar>
      <DxItem
        location="before"
        template="filterPanelTemplate"
      />
      <DxItem
        v-if="!isCurrentBreakPointXs && !isCurrentBreakPointMd"
        location="after"
        template="configPanelTemplate"
      />
      <DxItem
        name="exportButton"
        locate-in-menu="always"
      />
    </DxToolbar>
    <DxExport
      :enabled="true"
      :formats="['csv', 'xlsx']"
      :texts="{
        exportAll: 'Export to {0}'
      }"
    />
    <DxColumn
      data-field="category"
      :group-index="0"
      group-cell-template="groupCellTemplate"
    />

    <template #groupCellTemplate="{ data }">
      <div class="tw-font-bold xs:tw-pl-1">
        <span v-if="data.value">{{ data.value.replace(/^[0-9]+-/, '') }}</span>
      </div>
    </template>
    <DxColumn
      data-field="charge_title"
      :caption="isLoading ? 'Loading...' : `Account (in ${currency})`"
      :width="isCurrentBreakPointXs ? 150 : 250"
      :min-width="isCurrentBreakPointXs ? 150 : 250"
      :fixed="true"
      css-class="fixed-column-cell"
      fixed-position="left"
      :allow-sorting="false"
    />
    <DxColumn
      data-field="order"
      :sort-index="1"
      sort-order="asc"
      :visible="false"
    />
    <DxColumn
      data-field="only_advanced"
      :visible="false"
    />
    <DxColumn
      v-for="column in dateColumns"
      :key="column"
      :data-field="column"
      :min-width="100"
      :allow-sorting="false"
      cell-template="moneyCellTemplate"
      header-cell-template="dateHeaderCellTemplate"
    />
    <template #filterPanelTemplate>
      <FilterPanel
        ref="filterPanel"
        :default-interval="periodType"
        :default-date-range="dateRange"
        :default-marketplaces="marketplaces"
        :default-search="search"
        :expand-filters="expandFilters"
        :is-advanced-view="advancedView"
        @on-update="onFilterChange"
        @on-show-filter-click="expandFilters = !expandFilters"
        @aggregation-interval-changed="onAggregationIntervalChanged"
        @on-view-mode-change="onViewModeChange"
      />
    </template>
    <template #configPanelTemplate>
      <ConfigPanel
        ref="configPanel"
        :default-interval="periodType"
        :default-min-interval="getMinPeriodTypeForDateRange()"
        @aggregation-interval-changed="onAggregationIntervalChanged"
      />
    </template>
    <template #moneyCellTemplate="{ data }">
      <div
        class="tw-text-right"
        :class="{'text-success': !isNegative(data.value) && data.value !== '0.00', 'text-danger': isNegative(data.value) && data.value !== '0.00'}"
      >
        {{ data.value }}
      </div>
    </template>
    <template #dateHeaderCellTemplate="{ data }">
      <div>
        {{ getDateColumnHeader(data.column.dataField) }}
      </div>
    </template>
  </DxDataGrid>
</template>
<script>
import {
  DxDataGrid, DxColumn, DxPaging, DxGrouping, DxExport, DxItem, DxToolbar,
} from 'devextreme-vue/data-grid'
import moment from 'moment'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import ConfigPanel from '@/views/profit-and-loss/components/ConfigPanel.vue'
import FilterPanel from '@/views/profit-and-loss/components/FilterPanel.vue'
import profitAndLossMapper from '@/views/profit-and-loss/utils/utils'

export default {
  name: 'DataGrid',
  components: {
    FilterPanel,
    ConfigPanel,
    DxToolbar,
    DxItem,
    DxExport,
    DxPaging,
    DxDataGrid,
    DxColumn,
    DxGrouping,
  },
  data() {
    return {
      noResultsFoundMessage: 'No results found',
      periodType: 'month',
      minPeriodType: 'week',
      marketplaces: [],
      search: null,
      dateRange: {
        startDate: moment().subtract(6, 'month').startOf('month').toDate(),
        endDate: moment().subtract(1, 'month').endOf('month').toDate(),
      },
      dateColumns: [],
      datasource: [],
      currency: 'USD',
      isLoading: false,
      expandFilters: false,
      advancedView: false,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    isCurrentBreakPointMd() {
      return this.currentBreakPoint === 'md'
    },
    gridInstance() {
      return this.$refs.gridView.instance
    },
  },
  mounted() {
    this.gridInstance.filter([['only_advanced', '=', false]])
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      this.gridInstance.beginCustomLoading()
      this.$store.dispatch('profitAndLoss/fetchData', {
        period_type: this.periodType,
        start_date: moment(this.dateRange.startDate).format('YYYY-MM-DDT00:00:00'),
        end_date: moment(this.dateRange.endDate).format('YYYY-MM-DDT23:59:59'),
        marketplaces: this.marketplaces,
        search: this.search,
      }).then(response => {
        if (response.data) {
          const { transformedData, uniqueDates } = profitAndLossMapper(response.data)
          this.datasource = transformedData
          this.dateColumns = uniqueDates
          this.currency = response.currency
        }
      }).finally(() => {
        this.isLoading = false
        this.gridInstance.endCustomLoading()
      })
    },
    isNegative(value) {
      if (!value || !value.length) {
        return false
      }
      return value.indexOf('-') !== -1
    },
    getDateColumnHeader(value) {
      if (this.isLoading) {
        return 'Loading...'
      }
      const date = moment(value.replaceAll('_', '-'))
      if (this.periodType === 'quarter') {
        const quarter = date.quarter()
        const year = date.year()
        return `Q${quarter} ${year}`
      }
      let format
      switch (this.periodType) {
        case 'month':
          format = 'MMM YYYY'
          break
        case 'year':
          format = 'YYYY'
          break
        case 'day':
        case 'week':
        default:
          format = 'MMM DD, YYYY'
          break
      }
      return date.format(format)
    },
    onFilterChange(filter) {
      this.dateRange = filter.dateRange
      this.periodType = filter.aggregationInterval
      this.search = filter.search
      // const minPeriodType = this.getMinPeriodTypeForDateRange()
      // this.$refs.configPanel.setMinAggregationInterval(minPeriodType)
      // this.$refs.filterPanel.setMinAggregationInterval(minPeriodType)
      this.marketplaces = JSON.parse(JSON.stringify(filter.marketplaces))
      this.loadData()
    },
    onExported(e) {
      e.component.endUpdate()
    },
    onExporting(e) {
      e.component.beginUpdate()

      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Profit & Loss')

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        const date = new Date()
        if (e.format === 'csv') {
          workbook.csv.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Profit & Loss ${date.toLocaleDateString('en-US')}.csv`)
          })
        } else {
          workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Profit & Loss ${date.toLocaleDateString('en-US')}.xlsx`)
          })
        }
      })
      e.cancel = true
    },
    onRowPrepared(e) {
      if (e.rowType === 'group') {
        e.rowElement.classList.add('sxr-group-row')
        e.rowElement.firstChild.remove()
        e.rowElement.firstChild.setAttribute('colspan', parseInt(e.rowElement.firstChild.getAttribute('colspan'), 10) + 1)
      }
      if (e.rowType === 'data') {
        if (e.data.is_total && e.data.charge_title !== 'Refunds') {
          e.rowElement.classList.add('sxr-total-row')
        } else if (e.data.is_net) {
          e.rowElement.classList.add('sxr-net-row')
        }
      }
    },
    onAggregationIntervalChanged(value) {
      // if (!this.validatePeriodTypeForDateRange(value)) {
      //   this.$bvToast.toast('Date range is not valid for this aggregation interval', {
      //     title: 'Error',
      //     variant: 'danger',
      //     solid: true,
      //   })
      //   return
      // }
      this.periodType = value
      this.loadData()
    },
    onViewModeChange(value) {
      this.advancedView = value
      if (value) {
        this.gridInstance.clearFilter()
      } else {
        this.gridInstance.filter([['only_advanced', '=', value]])
      }
    },
    getDiffMonths() {
      return moment(this.dateRange.endDate).diff(moment(this.dateRange.startDate), 'months')
    },
    validatePeriodTypeForDateRange(value) {
      if (value === 'week') {
        return this.getDiffMonths() <= 6
      }

      if (value === 'month') {
        return this.getDiffMonths() <= 24
      }

      if (value === 'quarter') {
        return this.getDiffMonths() <= 60
      }

      return true
    },
    getMinPeriodTypeForDateRange() {
      if (this.getDiffMonths() <= 6) {
        return 'week'
      }

      if (this.getDiffMonths() <= 24) {
        return 'month'
      }

      if (this.getDiffMonths() <= 60) {
        return 'quarter'
      }

      return 'year'
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/common/grid.scss';
#profit-and-loss-data-grid {
  .sxr-group-row {
    color: var(--primary);
  }
  .sxr-total-row {
    td {
      background-color: rgba(255, 159, 67, 0.12) !important;
    }
  }
  .sxr-net-row {
    td {
      background-color: rgba(0, 165, 68, 0.12) !important;
    }
    font-weight: 900 !important;
  }
}
</style>
<style lang="scss" scoped>
#profit-and-loss-data-grid {
  min-height: calc(100dvh - 120px);
  max-height: calc(100dvh - 120px);
}
</style>
