/* eslint-disable guard-for-in,no-restricted-syntax */
import moment from 'moment'

const profitAndLossMapper = data => {
  const transformedData = []

  const uniqueDates = []

  const chargesByName = {}

  data.forEach(entry => {
    const date = moment(entry.date).format('YYYY_MM_DD')
    if (!uniqueDates.includes(date)) {
      uniqueDates.push(date)
    }

    entry.charges.forEach(charge => {
      if (!chargesByName[charge.name]) {
        chargesByName[charge.name] = {
          category: charge.category,
          charge_title: charge.title,
          is_net: false,
          is_total: false,
          order: 0,
          only_advanced: charge.only_advanced || false,
        }
      }
      if (!chargesByName[charge.name][date]) {
        chargesByName[charge.name][date] = charge.display_number
      } else {
        chargesByName[charge.name][date] += `, ${charge.display_number}`
      }
    })

    const orderMappings = {
      net_revenue: 1,
      refunds: 2,
      net_sales: 3,
    }

    for (const field of ['refunds', 'net_operating_profit', 'total_business_expenses', 'total_amazon_fees', 'total_cost', 'net_sales', 'net_revenue']) {
      if (entry[field] && entry[field].amount !== 0) {
        if (!chargesByName[entry[field].name]) {
          chargesByName[entry[field].name] = {
            category: entry[field].category,
            charge_title: entry[field].title,
            is_net: entry[field].is_net || false,
            is_total: entry[field].is_total || false,
            order: orderMappings[field] || 1,
            only_advanced: entry[field].only_advanced || false,
          }
        }

        if (!chargesByName[entry[field].name][date]) {
          chargesByName[entry[field].name][date] = entry[field].display_number
        } else {
          chargesByName[entry[field].name][date] += `, ${entry[field].display_number}`
        }
      }
    }
  })

  for (const name in chargesByName) {
    const charge = chargesByName[name]
    const transformedCharge = {
      category: charge.category,
      charge_title: charge.charge_title,
      is_net: charge.is_net,
      is_total: charge.is_total,
      order: charge.order,
      only_advanced: charge.only_advanced,
    }

    uniqueDates.forEach(date => {
      transformedCharge[date] = charge[date] || '0.00'
    })

    transformedData.push(transformedCharge)
  }

  return { transformedData, uniqueDates }
}

export default profitAndLossMapper
