<template>
  <div
    v-if="!isCurrentBreakPointXs"
    class="tw-grid tw-grid-cols-2 md:tw-grid-cols-5 tw-gap-2"
  >
    <div class="tw-relative md:tw-col-span-1 lg:tw-col-span-1">
      <date-range
        v-model="dateRange"
        :class="{'mobile': isCurrentBreakPointXs}"
        :value="dateRange"
        :range-options="rangeOptions"
        @update="onDateRangeChange"
      />
      <label class="flying-label">Date Range</label>
    </div>
    <DxTagBox
      v-model="marketplaces"
      :data-source="marketplaceOptions"
      :search-enabled="true"
      :max-displayed-tags="2"
      :show-multi-tag-only="false"
      display-expr="title"
      value-expr="id"
      placeholder="All Marketplaces"
      item-template="item"
      :on-value-changed="onFilterChange"
      width="100%"
    >
      <template #item="{ data }">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div class="flex-1">
            {{ data.title }}
          </div>
          <div>
            <CountryFlag :country="data.country.toLowerCase()" />
          </div>
        </div>
      </template>
    </DxTagBox>
    <DxTextBox
      v-model="search"
      class="tw-w-full"
      placeholder="Enter ASIN, SKU or keyword"
      :on-key-up="onFilterChange"
      value-change-event="keyup"
      width="100%"
    />
    <DxSelectBox
      v-if="isCurrentBreakPointMd"
      v-model="aggregationInterval"
      class="flex-fill flex-sm-grow-0"
      :items="aggregationIntervalOptions"
      display-expr="text"
      value-expr="value"
      :on-item-click="onAggregationIntervalChange"
      width="90%"
    />
    <DxCheckBox
      text="Advanced View"
      :value="isAdvancedView"
      :on-value-changed="onViewModeChange"
    />
  </div>
  <div
    v-else
    class="tw-flex tw-flex-col tw-gap-2"
  >
    <div class="tw-flex tw-flex-row tw-gap-2">
      <div class="tw-relative tw-flex-1">
        <date-range
          v-model="dateRange"
          :class="{'mobile': isCurrentBreakPointXs}"
          :value="dateRange"
          :range-options="rangeOptions"
          @update="onDateRangeChange"
        />
        <label class="flying-label">Date Range</label>
      </div>
      <DxButton
        icon="filter"
        type="default"
        width="35"
        height="35"
        :styling-mode="expandFilters ? 'contained' : 'outlined'"
        @click="onSearchButtonClick"
      />
    </div>
    <div
      v-if="expandFilters"
      class="tw-grid tw-grid-cols-2 tw-gap-2"
    >
      <DxTagBox
        v-model="marketplaces"
        :data-source="marketplaceOptions"
        :search-enabled="true"
        :max-displayed-tags="2"
        :show-multi-tag-only="false"
        display-expr="title"
        value-expr="id"
        placeholder="All Marketplaces"
        item-template="item"
        :on-value-changed="onFilterChange"
      >
        <template #item="{ data }">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="flex-1">
              {{ data.title }}
            </div>
            <div>
              <CountryFlag :country="data.country.toLowerCase()" />
            </div>
          </div>
        </template>
      </DxTagBox>
      <DxTextBox
        v-model="search"
        class="tw-w-full"
        placeholder="Enter ASIN, SKU or keyword"
        :on-key-up="onFilterChange"
        value-change-event="keyup"
      />
      <DxSelectBox
        v-model="aggregationInterval"
        class="flex-fill flex-sm-grow-0"
        :items="aggregationIntervalOptions"
        display-expr="text"
        value-expr="value"
        :on-item-click="onAggregationIntervalChange"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment/moment'
import DxTagBox from 'devextreme-vue/tag-box'
import CountryFlag from 'vue-country-flag'
import DxTextBox from 'devextreme-vue/text-box'
import { DxSelectBox } from 'devextreme-vue'
import DxButton from 'devextreme-vue/button'
import { DxCheckBox } from 'devextreme-vue/check-box'
import DateRange from '@/components/formElements/DateRange.vue'

const endPreviousMonth = moment()
  .startOf('month')
  .subtract(1, 'day').endOf('month')
  .toDate()
export default {
  name: 'FilterPanel',
  components: {
    DxCheckBox,
    DxButton,
    DxSelectBox,
    DxTextBox,
    DxTagBox,
    DateRange,
    CountryFlag,
  },
  props: {
    defaultSearch: {
      type: String,
      default: null,
    },
    defaultInterval: {
      type: String,
      default: 'month',
    },
    defaultMarketplaces: {
      type: Array,
      default: () => [],
    },
    defaultDateRange: {
      type: Object,
      default: () => ({
        startDate: moment().subtract(6, 'month').startOf('month').toDate(),
        endDate: endPreviousMonth,
      }),
    },
    expandFilters: {
      type: Boolean,
      default: false,
    },
    isAdvancedView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      dateRange: {},
      rangeOptions: {
        'This Month': [
          moment().startOf('month').toDate(),
          moment().endOf('month').toDate(),
        ],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month').toDate(),
          endPreviousMonth,
        ],
        'Last 3 Months': [
          moment().subtract(3, 'month').startOf('month').toDate(),
          endPreviousMonth,
        ],
        'Last 6 Months': [
          moment().subtract(6, 'month').startOf('month').toDate(),
          endPreviousMonth,
        ],
        'Last 12 Months': [
          moment().subtract(12, 'month').startOf('month').toDate(),
          endPreviousMonth,
        ],
        'Last Year': [
          moment().subtract(1, 'year').startOf('year').toDate(),
          moment().subtract(1, 'year').endOf('year').toDate(),
        ],
        'Year to Date': [
          moment().startOf('year').toDate(),
          moment().endOf('day').toDate(),
        ],
        'All Time': [
          moment('2010-01-01', 'YYYY-MM-DD').toDate(),
          moment().endOf('day').toDate(),
        ],
      },
      marketplaces: [],
      aggregationInterval: 'month',
      aggregationIntervalOptions: [
        // { text: 'Day', value: 'day' },
        { text: 'Week', value: 'week', availableFor: ['day', 'week'] },
        { text: 'Month', value: 'month', availableFor: ['day', 'week', 'month'] },
        { text: 'Quarter', value: 'quarter', availableFor: ['day', 'week', 'month', 'quarter'] },
        { text: 'Year', value: 'year', availableFor: ['day', 'week', 'month', 'quarter', 'year'] },
      ],
      debounce: null,
      selectedRange: null,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    isCurrentBreakPointMd() {
      return this.currentBreakPoint === 'md'
    },
    marketplaceOptions() {
      return this.$store.getters['app/availableMarketplaceOptions']
    },
  },
  created() {
    this.search = this.defaultSearch
    this.marketplaces = JSON.parse(JSON.stringify(this.defaultMarketplaces))
    this.dateRange = JSON.parse(JSON.stringify(this.defaultDateRange))
    this.aggregationInterval = this.defaultInterval
  },
  methods: {
    setMinAggregationInterval(value) {
      this.aggregationIntervalOptions = [
        // { text: 'Day', value: 'day' },
        { text: 'Week', value: 'week', availableFor: ['day', 'week'] },
        { text: 'Month', value: 'month', availableFor: ['day', 'week', 'month'] },
        { text: 'Quarter', value: 'quarter', availableFor: ['day', 'week', 'month', 'quarter'] },
        { text: 'Year', value: 'year', availableFor: ['day', 'week', 'month', 'quarter', 'year'] },
      ].filter(option => option.availableFor.indexOf(value) !== -1)
    },
    onFilterChange() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      this.debounce = setTimeout(() => {
        this.$emit('on-update', JSON.parse(JSON.stringify({
          search: this.search,
          marketplaces: this.marketplaces,
          dateRange: this.dateRange,
          aggregationInterval: this.aggregationInterval,
        })))
      }, 500)
    },
    onDateRangeChange(data) {
      this.selectedRange = data.rangeLabel
      this.onFilterChange()
    },
    onAggregationIntervalChange(data) {
      this.aggregationInterval = data.itemData.value
      this.$emit('aggregation-interval-changed', data.itemData.value)
    },
    onSearchButtonClick(e) {
      e.element.blur()
      this.$emit('on-show-filter-click', this.expandFilters)
    },
    onViewModeChange({ value }) {
      this.$emit('on-view-mode-change', value)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
