var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxDataGrid',{ref:"gridView",staticClass:"sxr-grid regular-text-color",class:{ 'filter-panel-expanded': _vm.expandFilters },attrs:{"id":"profit-and-loss-data-grid","width":"100%","height":"100%","data-source":_vm.datasource,"show-borders":false,"show-row-lines":true,"show-column-lines":true,"remote-operations":true,"word-wrap-enabled":true,"no-data-text":_vm.noResultsFoundMessage,"column-auto-width":true,"allow-column-reordering":false,"allow-column-resizing":false,"column-resizing-mode":"widget","on-row-prepared":_vm.onRowPrepared},on:{"exporting":_vm.onExporting,"exported":_vm.onExported},scopedSlots:_vm._u([{key:"groupCellTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"tw-font-bold xs:tw-pl-1"},[(data.value)?_c('span',[_vm._v(_vm._s(data.value.replace(/^[0-9]+-/, '')))]):_vm._e()])]}},{key:"filterPanelTemplate",fn:function(){return [_c('FilterPanel',{ref:"filterPanel",attrs:{"default-interval":_vm.periodType,"default-date-range":_vm.dateRange,"default-marketplaces":_vm.marketplaces,"default-search":_vm.search,"expand-filters":_vm.expandFilters,"is-advanced-view":_vm.advancedView},on:{"on-update":_vm.onFilterChange,"on-show-filter-click":function($event){_vm.expandFilters = !_vm.expandFilters},"aggregation-interval-changed":_vm.onAggregationIntervalChanged,"on-view-mode-change":_vm.onViewModeChange}})]},proxy:true},{key:"configPanelTemplate",fn:function(){return [_c('ConfigPanel',{ref:"configPanel",attrs:{"default-interval":_vm.periodType,"default-min-interval":_vm.getMinPeriodTypeForDateRange()},on:{"aggregation-interval-changed":_vm.onAggregationIntervalChanged}})]},proxy:true},{key:"moneyCellTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"tw-text-right",class:{'text-success': !_vm.isNegative(data.value) && data.value !== '0.00', 'text-danger': _vm.isNegative(data.value) && data.value !== '0.00'}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"dateHeaderCellTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getDateColumnHeader(data.column.dataField))+" ")])]}}])},[_c('DxPaging',{attrs:{"enabled":false}}),_c('DxGrouping',{attrs:{"allow-collapsing":false}}),_c('DxToolbar',[_c('DxItem',{attrs:{"location":"before","template":"filterPanelTemplate"}}),(!_vm.isCurrentBreakPointXs && !_vm.isCurrentBreakPointMd)?_c('DxItem',{attrs:{"location":"after","template":"configPanelTemplate"}}):_vm._e(),_c('DxItem',{attrs:{"name":"exportButton","locate-in-menu":"always"}})],1),_c('DxExport',{attrs:{"enabled":true,"formats":['csv', 'xlsx'],"texts":{
      exportAll: 'Export to {0}'
    }}}),_c('DxColumn',{attrs:{"data-field":"category","group-index":0,"group-cell-template":"groupCellTemplate"}}),_c('DxColumn',{attrs:{"data-field":"charge_title","caption":_vm.isLoading ? 'Loading...' : ("Account (in " + _vm.currency + ")"),"width":_vm.isCurrentBreakPointXs ? 150 : 250,"min-width":_vm.isCurrentBreakPointXs ? 150 : 250,"fixed":true,"css-class":"fixed-column-cell","fixed-position":"left","allow-sorting":false}}),_c('DxColumn',{attrs:{"data-field":"order","sort-index":1,"sort-order":"asc","visible":false}}),_c('DxColumn',{attrs:{"data-field":"only_advanced","visible":false}}),_vm._l((_vm.dateColumns),function(column){return _c('DxColumn',{key:column,attrs:{"data-field":column,"min-width":100,"allow-sorting":false,"cell-template":"moneyCellTemplate","header-cell-template":"dateHeaderCellTemplate"}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }