<template>
  <b-form-radio-group
    v-model="aggregationInterval"
    size="sm"
    button-variant="flat-primary"
    :options="availableOptions"
    buttons
    name="radios-btn-default"
    @change="handleChange"
  />
</template>

<script>
import { BFormRadioGroup } from 'bootstrap-vue'

export default {
  name: 'AggregationIntervalSwitch',
  components: {
    BFormRadioGroup,
  },
  props: {
    value: {
      type: String,
      default: 'month',
      validator(value) {
        return ['day', 'week', 'month', 'year', 'quarter'].indexOf(value) !== -1
      },
    },
  },
  data() {
    return {
      aggregationInterval: 'month',
      minAggregationInterval: 'week',
    }
  },
  computed: {
    availableOptions() {
      return [
        // { text: 'Day', value: 'day', availableFor: ['day'] },
        { text: 'Week', value: 'week', availableFor: ['day', 'week'] },
        { text: 'Month', value: 'month', availableFor: ['day', 'week', 'month'] },
        { text: 'Quarter', value: 'quarter', availableFor: ['day', 'week', 'month', 'quarter'] },
        { text: 'Year', value: 'year', availableFor: ['day', 'week', 'month', 'quarter', 'year'] },
      ].map(option => {
        if (option.availableFor.indexOf(this.minAggregationInterval) === -1) {
          return {
            ...option,
            disabled: true,
          }
        }
        return {
          ...option,
          disabled: false,
        }
      })
    },
  },
  mounted() {
    this.aggregationInterval = this.value
  },
  methods: {
    handleChange(value) {
      this.$emit('input', value)
      this.$emit('value-changed', value)
    },
    setMinAggregationInterval(value) {
      this.minAggregationInterval = value
      this.aggregationInterval = value
    },
  },
}
</script>

<style lang="scss" scoped>
  .btn-group-toggle::v-deep  {
    .disabled {
      pointer-events: none;
    }
  }
</style>
