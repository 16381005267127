<template>
  <div class="tw-flex tw-flex-row tw-gap-2">
    <AggregationIntervalSwitch
      ref="aggregationIntervalSwitch"
      :value="defaultInterval"
      @value-changed="onAggregationIntervalChange"
    />
  </div>
</template>
<script>

import AggregationIntervalSwitch from '@/views/profit-and-loss/components/AggregationIntervalSwitch.vue'

export default {
  name: 'ConfigPanel',
  components: { AggregationIntervalSwitch },
  props: {
    defaultInterval: {
      type: String,
      default: 'month',
    },
  },
  methods: {
    onAggregationIntervalChange(value) {
      this.$emit('aggregation-interval-changed', value)
    },
    setMinAggregationInterval(value) {
      this.$refs.aggregationIntervalSwitch.setMinAggregationInterval(value)
      this.aggregationInterval = value
    },
  },
}
</script>

<style scoped lang="scss">

</style>
