<template>
  <DataGrid />
</template>
<script>
import DataGrid from '@/views/profit-and-loss/components/DataGrid.vue'

export default {
  name: 'ProfitAndLoss',
  components: { DataGrid },
}
</script>

<style scoped lang="scss">

</style>
